import { template as template_bd84d0f7aacd4425be1b541c6d0d565c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_bd84d0f7aacd4425be1b541c6d0d565c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
