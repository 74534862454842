import { template as template_57613741a2f140049b3dc0ec2583c3bb } from "@ember/template-compiler";
const SidebarSectionMessage = template_57613741a2f140049b3dc0ec2583c3bb(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
