import { template as template_cdc27acd9c014705a0868f1c0bb2b176 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_cdc27acd9c014705a0868f1c0bb2b176(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
