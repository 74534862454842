import { template as template_a154195f0fce43e8b49d2bda5c23bf8d } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_a154195f0fce43e8b49d2bda5c23bf8d(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
